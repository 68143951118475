import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
// import { useParams } from 'react-router-dom';


const WebcamCapture = () => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [isDisabled, setIsDisabled] = useState(false); // initial value is true
  const [isOpen, setIsOpen] = useState(false);
  // const openPopup = () => setIsOpen(true);
  const closePopup = () => setIsOpen(false);


  // const {session_id}= useParams();

  const fullurl = new URLSearchParams(window.location.search);  
  const getallid = []; // url to get id
// eslint-disable-next-line
  for (const [key, value] of fullurl) {
      getallid.push(value);
  }
    
  
  const session_id = getallid[0];  //session id

  const capture = async () => {
    const imgSrc = webcamRef.current.getScreenshot();
    console.log(imgSrc);      
    setImgSrc(imgSrc);
  
  };

  const upload_img = async () => {
     console.log(session_id)
    
      await axios.post('https://rigour-web-api2.ekamr.com/upload/selfie', { image: imgSrc ,sessionId:session_id}).then((response) => {
        console.log(response.status);
        if(response.status === 200){
          console.log("hello")
          setIsDisabled(!isDisabled);
          setIsOpen(!isOpen);

        }
      });
      console.log('Image sent to server.');
     
  
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h1>Webcam Data to Server</h1>

      {isOpen && (
        <div className='popup'>
          <button onClick={closePopup} className='closebtn'>X</button>
          <p className='success'>Selfie Captured Successfully.</p>
        </div>
      )}

      {
        imgSrc !== "" &&
        <div style={{ marginTop: '20px' }}>
          <h2>Captured Image</h2>
          <img src={imgSrc} alt="Captured" style={{ marginTop: '10px' }} />
        </div>
}{  imgSrc !== "" &&
        <div><button
          style={{
            marginTop: '10px',
            fontSize: '20px',
            backgroundColor: '#423fff',
            cursor: 'pointer',
            borderRadius: "10px",
            color: "white",
            padding: "10px"
          }}
          disabled={isDisabled}
          onClick={upload_img}
        >
          Upload
        </button>
        </div>
      }
      <div style={{ margin: 'auto' }}>
        <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
      </div>
      
      <div style={{ margin: 'auto' }}>
        <button
          style={{
            marginTop: '10px',
            fontSize: '20px',
            backgroundColor: '#423fff',
            cursor: 'pointer',
            borderRadius: "10px",
            color: "white",
            padding: "10px"
          }}
          onClick={capture}
        >
          Capture
        </button>
      </div>

   
    </div>
  );

};

export default WebcamCapture;
