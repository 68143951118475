import "./App.css";
import React from 'react';

//import CustomWebcam from "./CustomWebcam"; // import it
import WebcamCapture from "./WebcamCapture"; // import it
// import { BrowserRouter, Routes, Route } from "react-router-dom";


const App = () => {
  return (
    <div className="App">
        {/* <BrowserRouter>
          <Routes>
              <Route path="/selfie/capture/:session_id" element={<WebcamCapture />}>
             
            </Route>           
          
          </Routes>
          
        </BrowserRouter> */}
      <WebcamCapture />
     
    </div>
  );
};

export default App;